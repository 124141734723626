import { createAsyncThunk } from '@reduxjs/toolkit';
import { fmProjectsService } from "../../../services/financeManagement/fmProjects.services";
import { showHideLoader } from '../../common/common.slice';


// Get projects list action
// change-req 
const getFinanceProjectsListAction = createAsyncThunk('fmProjectsSlice/getFinanceProjectsListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectListService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project center invoices list action
// change-req 
const getProjectInvoicesListAction = createAsyncThunk('fmProjectsSlice/getProjectInvoicesListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectInvoicesListService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project center invoice details action
// change-req 
const getProjectInvoiceDetailsAction = createAsyncThunk('fmProjectsSlice/getProjectInvoiceDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectInvoiceDetailsService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Resync project center invoice details action
const resyncProjectInvoiceDetailsAction = createAsyncThunk('fmProjectsSlice/resyncProjectInvoiceDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.resyncProjectInvoiceDetailsService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// split invoice action
const splitInvoiceAction = createAsyncThunk('fmProjectsSlice/splitInvoiceAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    
    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.splitInvoiceService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500)
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500)
            return rejectWithValue(error?.response);
        });
});

// Upload attachment for expense action
const uploadInvoiceAttachmentAction = createAsyncThunk('fmProjectsSlice/uploadInvoiceAttachmentAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.uploadInvoiceAttachmentService({
        ...payload,
        id: userObject?.id
    })
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// modify invoice action
const updateInvoiceAction = createAsyncThunk('fmProjectsSlice/updateInvoiceAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    // dispatch(showHideLoader(true));
    
    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.updateInvoiceService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            // dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            // dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Release project center invoice action
// change-req 
const releaseInvoicesAction = createAsyncThunk('fmProjectsSlice/releaseInvoicesAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    
    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.releaseInvoiceService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Release project center invoice action
// change-req 
const unreleaseInvoicesAction = createAsyncThunk('fmProjectsSlice/unreleaseInvoicesAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    
    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.unreleaseInvoiceService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Upload project center invoice action
const uploadPdfForInvoiceAction = createAsyncThunk('fmProjectsSlice/uploadPdfForInvoiceAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return fmProjectsService.uploadPdfForInvoiceService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// Upload project center change order action
const uploadPdfForChangeOrderAction = createAsyncThunk('fmProjectsSlice/uploadPdfForChangeOrderAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return fmProjectsService.uploadPdfForChangeOrderService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// Expeneses
const getProjectExpensesListAction = createAsyncThunk('fmProjectsSlice/getProjectExpensesListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    // const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectExpensesService(payload)
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

const addProjectEstimatedExpenseAction = createAsyncThunk('fmProjectsSlice/addProjectEstimatedExpenseAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    // const { login: { userObject } } = await getState() as any;

    return fmProjectsService.addProjectEstimatedExpenseService(payload)
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

const updateProjectEstimatedExpenseAction = createAsyncThunk('fmProjectsSlice/updateProjectEstimatedExpenseAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    // const { login: { userObject } } = await getState() as any;

    return fmProjectsService.updateProjectEstimatedExpenseService(payload)
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

const deleteProjectEstimatedExpenseAction = createAsyncThunk('fmProjectsSlice/deleteProjectEstimatedExpenseAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    // const { login: { userObject } } = await getState() as any;

    return fmProjectsService.deleteProjectEstimatedExpenseService(payload)
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

const confirmProjectExpenseAction = createAsyncThunk('fmProjectsSlice/confirmProjectExpenseAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    return fmProjectsService.confirmProjectExpenseService(payload)
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Upload expense action
const uploadExpenseAction = createAsyncThunk('fmProjectsSlice/uploadExpenseAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    // dispatch(showHideLoader(true));
    return fmProjectsService.uploadExpenseService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// Upload attachment for expense action
const uploadExpenseAttachmentAction = createAsyncThunk('fmProjectsSlice/uploadExpenseAttachmentAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    return fmProjectsService.uploadExpenseAttachmentService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// Upload project center change order action
const getExpenseDetailsAction = createAsyncThunk('fmProjectsSlice/getExpenseDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return fmProjectsService.expenseDetailsService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// get unverified expenses list action
const getUnverifiedExpensesListAction = createAsyncThunk('fmProjectsSlice/getUnverifiedExpensesListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return fmProjectsService.getUnverifiedExpensesListService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// get unverified expenses list action
const compareUnverifiedExpenseAction = createAsyncThunk('fmProjectsSlice/compareUnverifiedExpenseAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return fmProjectsService.compareUnverifiedExpenseService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});


// Get project overview details action
// change-req 
const getProjectOverviewDetailsAction = createAsyncThunk('fmProjectsSlice/getProjectOverviewDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.projectOverviewDetailsService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project timeline task-list action
// change-req 
const getProjectTimelineListAction = createAsyncThunk('fmProjectsSlice/getProjectTimelineListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectTimelineListService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Update project task connections action
// change-req
const updateTaskConnectionAction = createAsyncThunk('fmProjectsSlice/updateTaskConnectionAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }
    
    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.updateTaskConnectionsService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project phase tasks list action
// change-req
const getProjectTasksListAction = createAsyncThunk('fmProjectsSlice/getProjectTasksListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectTasksService({
        ...payload,
        id: userObject?.id
    })
        // response is being altered in the slice
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project overview task details action
// change-req
const getProjectOverviewTaskDetailsAction = createAsyncThunk('fmProjectsSlice/getProjectOverviewTaskDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.projectOverviewTaskDetailsService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500)
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500)
            return rejectWithValue(error?.response);
        });
});


// Get project milestones action
const getProjectMilestonesAction = createAsyncThunk('fmProjectsSlice/getProjectMilestonesAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    return fmProjectsService.projectMilestonesService({
        ...payload,
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project phases action
// change-req
const getProjectPhasesAction = createAsyncThunk('fmProjectsSlice/getProjectPhasesAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.getProjectPhasesList({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Add project task action
// change-req
const addProjectTaskAction = createAsyncThunk('fmProjectsSlice/addProjectTaskAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.addProjectTaskService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Update project task action
// change-req 
const updateProjectTaskAction = createAsyncThunk('fmProjectsSlice/updateProjectTaskAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.updateProjectTaskService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Delete project task action
// change-req
const deleteProjectTaskAction = createAsyncThunk('fmProjectsSlice/deleteeProjectTaskAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { login: { userObject } } = await getState() as any;

    return fmProjectsService.deleteProjectTaskService({
        ...payload,
        id: userObject?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// get change order Action
const getChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/getChangeOrderAction', (payload: any, { dispatch, rejectWithValue }) => {
      dispatch(showHideLoader(true));
      return fmProjectsService.getChangeOrderService(payload)
        .then((response) => {
          setTimeout(() => {
            dispatch(showHideLoader(false))
          }, 500)
          return response
        })
        .catch((error: { response: unknown; }) => {
          setTimeout(() => {
            dispatch(showHideLoader(false))
          }, 500)
          return rejectWithValue(error?.response)
        })
    }
  );

// get change order items Action
const getChangeOrderItemsAction = createAsyncThunk(
    'fmProjectsSlice/getChangeOrderItemsAction', (payload: any, { dispatch, rejectWithValue }) => {
        //   dispatch(showHideLoader(true));
        return fmProjectsService.getChangeOrderItemsService(payload)
            .then((response) => {
                //   setTimeout(() => {
                //     dispatch(showHideLoader(false))
                //   }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                //   setTimeout(() => {
                //     dispatch(showHideLoader(false))
                //   }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// create change order Action
const createChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/createChangeOrderAction', (dealId: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.createChangeOrderService(dealId)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// create change order Action
const saveChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/saveChangeOrderAction', ({change_order_id, payload}:any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.saveChangeOrderService(change_order_id, payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// create change order Action
const getChangeOrderDetailsAction = createAsyncThunk(
    'fmProjectsSlice/getChangeOrderDetailsAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.getChangeOrderDetailsService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// get change order terms Action
const getChangeOrderTermsAction = createAsyncThunk(
    'fmProjectsSlice/getChangeOrderTermsAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.getChangeOrderTermsService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);


// save change order notes Action
const saveChangeOrderNotesAction = createAsyncThunk(
    'fmProjectsSlice/saveChangeOrderNotesAction', ({change_order_id,payload}: any, { dispatch, rejectWithValue }) => {
        // dispatch(showHideLoader(true));
        return fmProjectsService.saveChangeOrderNotesService(change_order_id,payload)
            .then((response) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// save change order Intro Action
const saveChangeOrderIntroAction = createAsyncThunk(
    'fmProjectsSlice/saveChangeOrderIntroAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.saveChangeOrderIntroService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const uploadChangeOrderPdfAction = createAsyncThunk(
    'fmProjectsSlice/uploadChangeOrderPdfAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.uploadChangeOrderPdfService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);


// upload change order pdf Action
const adjustChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/adjustChangeOrderAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.adjustChangeOrderService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const changeOrderAttachmentListAction = createAsyncThunk(
    'fmProjectsSlice/changeOrderAttachmentListAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.changeOrderAttachmentListService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const removeChangeOrderAttachmentAction = createAsyncThunk(
    'fmProjectsSlice/removeChangeOrderAttachmentAction', (attachmentId: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.removeChangeOrderAttachmentService(attachmentId)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const exportChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/exportChangeOrderAction', ({change_order_id,payload}: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.exportChangeOrderService(change_order_id,payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// delete change order Action
const deleteChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/deleteChangeOrderAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.deleteChangeOrderService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// duplicate change order Action
const duplicateChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/duplicateChangeOrderAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.duplicateChangeOrderService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// get co payment schedule Action
const getCOPaymentScheduleAction = createAsyncThunk(
    'fmProjectsSlice/getCOPaymentScheduleAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.getCOPaymentScheduleService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// get co payment schedule Action
const updateCOPaymentScheduleAction = createAsyncThunk(
    'fmProjectsSlice/updateCOPaymentScheduleAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.updateCOPaymentScheduleService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Rfp List Action
const rfpListAction = createAsyncThunk(
    'fmProjectsSlice/rfpListAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.rfpListService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Create Rfp Action
const createRfpAction = createAsyncThunk(
    'fmProjectsSlice/createRfpAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.createRfpService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Update Rfp Action
const updateRfpAction = createAsyncThunk(
    'fmProjectsSlice/updateRfpAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.updateRfpService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Unrelease change order Action
const unreleaseChangeOrderAction = createAsyncThunk(
    'fmProjectsSlice/unreleaseChangeOrderAction', (change_orde_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return fmProjectsService.unreleaseChangeOrder(change_orde_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// rearrange overview list Action
// change-req 
const rearrangeOverviewListAction = createAsyncThunk(
    'fmProjectsSlice/rearrangeOverviewListAction', async(payload: any, { getState, dispatch, rejectWithValue }) => {
        // dispatch(showHideLoader(true));

        const { login: { userObject } } = await getState() as any;

        return fmProjectsService.rearrangeProjectOverviewDetailsService({
            ...payload,
            id: userObject?.id
        })
            .then((response) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// generate zip Action
const generateZipAction = createAsyncThunk(
    'fmProjectsSlice/generateZipAction', (payload: any, { dispatch, rejectWithValue }) => {
        // dispatch(showHideLoader(true));
        return fmProjectsService.generateZip(payload)
            .then((response) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);


// Export all Projects Action
export const fmProjectsAction = {
    getFinanceProjectsListAction,
    getProjectInvoicesListAction,
    getProjectInvoiceDetailsAction,
    splitInvoiceAction,
    uploadInvoiceAttachmentAction,
    resyncProjectInvoiceDetailsAction,
    updateInvoiceAction,
    releaseInvoicesAction,
    unreleaseInvoicesAction,
    uploadPdfForInvoiceAction,
    uploadPdfForChangeOrderAction,

    getProjectExpensesListAction,
    addProjectEstimatedExpenseAction,
    updateProjectEstimatedExpenseAction,
    deleteProjectEstimatedExpenseAction,
    confirmProjectExpenseAction,
    uploadExpenseAction,
    uploadExpenseAttachmentAction,
    getExpenseDetailsAction,
    getUnverifiedExpensesListAction,
    compareUnverifiedExpenseAction,

    getProjectOverviewDetailsAction,
    getProjectTimelineListAction,
    updateTaskConnectionAction,
    getProjectTasksListAction,
    getProjectOverviewTaskDetailsAction,
    getProjectMilestonesAction,
    getProjectPhasesAction,
    addProjectTaskAction,
    updateProjectTaskAction,
    deleteProjectTaskAction,
    getChangeOrderAction,
    getChangeOrderItemsAction,
    createChangeOrderAction,
    saveChangeOrderAction,
    getChangeOrderDetailsAction,
    getChangeOrderTermsAction,
    saveChangeOrderNotesAction,
    saveChangeOrderIntroAction,
    uploadChangeOrderPdfAction,
    changeOrderAttachmentListAction,
    removeChangeOrderAttachmentAction,
    exportChangeOrderAction,
    deleteChangeOrderAction,
    duplicateChangeOrderAction,
    getCOPaymentScheduleAction,
    updateCOPaymentScheduleAction,
    adjustChangeOrderAction,
    
    rfpListAction,
    createRfpAction,
    updateRfpAction,
    unreleaseChangeOrderAction,
    rearrangeOverviewListAction,
    generateZipAction
}